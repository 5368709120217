import { useEffect } from 'react'
import { toast } from 'react-hot-toast'

// Function to show a toast message

const useInternetConnectionStatus = () => {
  useEffect(() => {
    const handleOnline = () => {
      toast.success('You are online.')
    }

    const handleOffline = () => {
      toast.error('You are offline. Please check your internet connection.')
    }

    // Subscribe to online/offline events
    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    // Cleanup event listeners when the component unmounts
    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [])
}

export default useInternetConnectionStatus
