import { produce } from 'immer'
import { applyMiddleware, createStore } from 'redux'
import axios from 'axios'
import reduxThunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import { set } from 'lodash'

const api = process.env.REACT_APP_BACKEND_URL
let token = sessionStorage.getItem('accessToken')
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}
/* ------------- Actions ------------- */
const SET_SHOW_SIDEBAR = 'APP/SET_SHOW_SIDEBAR'
const SET_UNFOLDABLE_SIDEBAR = 'APP/SET_UNFOLDABLE_SIDEBAR'
const FETCH_COURSES = 'APP/FETCH_COURSES'
const FETCH_COURSES_SUCCESS = 'APP/FETCH_COURSES_SUCCESS'
const FETCH_COURSES_FAIL = 'APP/FETCH_COURSES_FAIL'
const CREATE_COURSE = 'APP/CREATE_COURSE'
const CREATE_COURSE_SUCCESS = 'APP/CREATE_COURSE_SUCCESS'
const CREATE_COURSE_FAIL = 'APP/CREATE_COURSE_FAIL'
const EDIT_COURSE = 'APP/EDIT_COURSE'
const EDIT_COURSE_SUCCESS = 'APP/EDIT_COURSE_SUCCESS'
const EDIT_COURSE_FAIL = 'APP/EDIT_COURSE_FAIL'
const DELETE_COURSE = 'APP/DELETE_COURSE'
const DELETE_COURSE_SUCCESS = 'APP/DELETE_COURSE_SUCCESS'
const DELETE_COURSE_FAIL = 'APP/DELETE_COURSE_FAIL'
const CREATE_LEVEL = 'APP/CREATE_LEVEL'
const CREATE_LEVEL_SUCCESS = 'APP/CREATE_LEVEL_SUCCESS'
const CREATE_LEVEL_FAIL = 'APP/CREATE_LEVEL_FAIL'
const EDIT_LEVEL = 'APP/EDIT_LEVEL'
const EDIT_LEVEL_SUCCESS = 'APP/EDIT_LEVEL_SUCCESS'
const EDIT_LEVEL_FAIL = 'APP/EDIT_LEVEL_FAIL'
const DELETE_LEVEL = 'APP/DELETE_LEVEL'
const DELETE_LEVEL_SUCCESS = 'APP/DELETE_LEVEL_SUCCESS'
const DELETE_LEVEL_FAIL = 'APP/DELETE_LEVEL_FAIL'
const CREATE_UNIT = 'APP/CREATE_UNIT'
const CREATE_UNIT_SUCCESS = 'APP/CREATE_UNIT_SUCCESS'
const CREATE_UNIT_FAIL = 'APP/CREATE_UNIT_FAIL'
const EDIT_UNIT = 'APP/EDIT_UNIT'
const EDIT_UNIT_SUCCESS = 'APP/EDIT_UNIT_SUCCESS'
const EDIT_UNIT_FAIL = 'APP/EDIT_UNIT_FAIL'
const DELETE_UNIT = 'APP/DELETE_UNIT'
const DELETE_UNIT_SUCCESS = 'APP/DELETE_UNIT_SUCCESS'
const DELETE_UNIT_FAIL = 'APP/DELETE_UNIT_FAIL'
const CREATE_LESSON = 'APP/CREATE_LESSON'
const CREATE_LESSON_SUCCESS = 'APP/CREATE_LESSON_SUCCESS'
const CREATE_LESSON_FAIL = 'APP/CREATE_LESSON_FAIL'
const EDIT_LESSON = 'APP/EDIT_LESSON'
const EDIT_LESSON_SUCCESS = 'APP/EDIT_LESSON_SUCCESS'
const EDIT_LESSON_FAIL = 'APP/EDIT_LESSON_FAIL'
const DELETE_LESSON = 'APP/DELETE_LESSON'
const DELETE_LESSON_SUCCESS = 'APP/DELETE_LESSON_SUCCESS'
const DELETE_LESSON_FAIL = 'APP/DELETE_LESSON_FAIL'
const REGISTER_USER = 'APP/REGISTER_USER'
const REGISTER_USER_SUCCESS = 'APP/REGISTER_USER_SUCCESS'
const REGISTER_USER_FAIL = 'APP/REGISTER_USER_FAIL'
const LOGIN_USER = 'APP/LOGIN_USER'
const LOGIN_USER_SUCCESS = 'APP/LOGIN_USER_SUCCESS'
const LOGIN_USER_FAIL = 'APP/LOGIN_USER_FAIL'
const REFRESH_USER_TOKEN = 'APP/REFRESH_USER_TOKEN'
const REFRESH_USER_TOKEN_SUCCESS = 'APP/REFRESH_USER_TOKEN_SUCCESS'
const REFRESH_USER_TOKEN_FAIL = 'APP/REFRESH_USER_TOKEN_FAIL'
const GET_USER_INFO = 'APP/GET_USER_INFO'
const GET_USER_INFO_SUCCESS = 'APP/GET_USER_INFO_SUCCESS'
const GET_USER_INFO_FAIL = 'APP/GET_USER_INFO_FAIL'
const FETCH_USERS = 'APP/FETCH_USERS'
const FETCH_USERS_SUCCESS = 'APP/FETCH_USERS_SUCCESS'
const FETCH_USERS_FAIL = 'APP/FETCH_USERS_FAIL'
const FETCH_USER_SUBSCRIPTIONS = 'APP/FETCH_USER_SUBSCRIPTIONS'
const FETCH_USER_SUBSCRIPTIONS_SUCCESS = 'APP/FETCH_USER_SUBSCRIPTIONS_SUCCESS'
const FETCH_USER_SUBSCRIPTIONS_FAIL = 'APP/FETCH_USER_SUBSCRIPTIONS_FAIL'
const FETCH_USER_PAYMENTS = 'APP/FETCH_USER_PAYMENTS'
const FETCH_USER_PAYMENTS_SUCCESS = 'APP/FETCH_USER_PAYMENTS_SUCCESS'
const FETCH_USER_PAYMENTS_FAIL = 'APP/FETCH_USER_PAYMENTS_FAIL'
const FETCH_QUESTIONS = 'APP/FETCH_QUESTIONS'
const FETCH_QUESTIONS_SUCCESS = 'APP/FETCH_QUESTIONS_SUCCESS'
const FETCH_QUESTIONS_FAIL = 'APP/FETCH_QUESTIONS_FAIL'
const CREATE_QUESTION = 'APP/CREATE_QUESTION'
const CREATE_QUESTION_SUCCESS = 'APP/CREATE_QUESTION_SUCCESS'
const CREATE_QUESTION_FAIL = 'APP/CREATE_QUESTION_FAIL'
const EDIT_QUESTION = 'APP/EDIT_QUESTION'
const EDIT_QUESTION_SUCCESS = 'APP/EDIT_QUESTION_SUCCESS'
const EDIT_QUESTION_FAIL = 'APP/EDIT_QUESTION_FAIL'
const DELETE_QUESTION = 'APP/DELETE_QUESTION'
const DELETE_QUESTION_SUCCESS = 'APP/DELETE_QUESTION_SUCCESS'
const DELETE_QUESTION_FAIL = 'APP/DELETE_QUESTION_FAIL'
const FETCH_VIDEOS = 'APP/FETCH_VIDEOS'
const FETCH_VIDEOS_SUCCESS = 'APP/FETCH_VIDEOS_SUCCESS'
const FETCH_VIDEOS_FAIL = 'APP/FETCH_VIDEOS_FAIL'
const FETCH_VIDEO_BY_TITLE = 'APP/FETCH_VIDEO_BY_TITLE'
const FETCH_VIDEO_BY_TITLE_SUCCESS = 'APP/FETCH_VIDEO_BY_TITLE_SUCCESS'
const FETCH_VIDEO_BY_TITLE_FAIL = 'APP/FETCH_VIDEO_BY_TITLE_FAIL'
const CREATE_VIDEO = 'APP/CREATE_VIDEO'
const CREATE_VIDEO_SUCCESS = 'APP/CREATE_VIDEO_SUCCESS'
const CREATE_VIDEO_FAIL = 'APP/CREATE_VIDEO_FAIL'
const EDIT_VIDEO = 'APP/EDIT_VIDEO'
const EDIT_VIDEO_SUCCESS = 'APP/EDIT_VIDEO_SUCCESS'
const EDIT_VIDEO_FAIL = 'APP/EDIT_VIDEO_FAIL'
const DELETE_VIDEO = 'APP/DELETE_VIDEO'
const DELETE_VIDEO_SUCCESS = 'APP/DELETE_VIDEO_SUCCESS'
const DELETE_VIDEO_FAIL = 'APP/DELETE_VIDEO_FAIL'
const FETCH_OFFERS = 'APP/FETCH_OFFERS'
const FETCH_OFFERS_SUCCESS = 'APP/FETCH_OFFERS_SUCCESS'
const FETCH_OFFERS_FAIL = 'APP/FETCH_OFFERS_FAIL'
const CREATE_OFFER = 'APP/CREATE_OFFER'
const CREATE_OFFER_SUCCESS = 'APP/CREATE_OFFER_SUCCESS'
const CREATE_OFFER_FAIL = 'APP/CREATE_OFFER_FAIL'
const EDIT_OFFER = 'APP/EDIT_OFFER'
const EDIT_OFFER_SUCCESS = 'APP/EDIT_OFFER_SUCCESS'
const EDIT_OFFER_FAIL = 'APP/EDIT_OFFER_FAIL'
const DELETE_OFFER = 'APP/DELETE_OFFER'
const DELETE_OFFER_SUCCESS = 'APP/DELETE_OFFER_SUCCESS'
const DELETE_OFFER_FAIL = 'APP/DELETE_OFFER_FAIL'
const FETCH_WORDS = 'APP/FETCH_WORDS'
const FETCH_WORDS_SUCCESS = 'APP/FETCH_WORDS_SUCCESS'
const FETCH_WORDS_FAIL = 'APP/FETCH_WORDS_FAIL'
const CHANGE_PASSWORD = 'APP/CHANGE_PASSWORD'
const CHANGE_PASSWORD_SUCCESS = 'APP/CHANGE_PASSWORD_SUCCESS'
const CHANGE_PASSWORD_FAIL = 'APP/CHANGE_PASSWORD_FAIL'
const CREATE_WORD = 'APP/CREATE_WORD'
const CREATE_WORD_SUCCESS = 'APP/CREATE_WORD_SUCCESS'
const CREATE_WORD_FAIL = 'APP/CREATE_WORD_FAIL'
const EDIT_WORD = 'APP/EDIT_WORD'
const EDIT_WORD_SUCCESS = 'APP/EDIT_WORD_SUCCESS'
const EDIT_WORD_FAIL = 'APP/EDIT_WORD_FAIL'
const DELETE_WORD = 'APP/DELETE_WORD'
const DELETE_WORD_SUCCESS = 'APP/DELETE_WORD_SUCCESS'
const DELETE_WORD_FAIL = 'APP/DELETE_WORD_FAIL'
const GET_UPLOAD_CREDENTIALS = 'APP/GET_UPLOAD_CREDENTIALS'
const GET_UPLOAD_CREDENTIALS_SUCCESS = 'APP/GET_UPLOAD_CREDENTIALS_SUCCESS'
const GET_UPLOAD_CREDENTIALS_FAIL = 'APP/GET_UPLOAD_CREDENTIALS_FAIL'
const OBTAIN_OTP = 'APP/OBTAIN_OTP'
const OBTAIN_OTP_SUCCESS = 'APP/OBTAIN_OTP_SUCCESS'
const OBTAIN_OTP_FAIL = 'APP/OBTAIN_OTP_FAIL'
const CREATE_SECTION = 'APP/CREATE_SECTION'
const CREATE_SECTION_SUCCESS = 'APP/CREATE_SECTION_SUCCESS'
const CREATE_SECTION_FAIL = 'APP/CREATE_SECTION_FAIL'
const EDIT_SECTION = 'APP/EDIT_SECTION'
const EDIT_SECTION_SUCCESS = 'APP/EDIT_SECTION_SUCCESS'
const EDIT_SECTION_FAIL = 'APP/EDIT_SECTION_FAIL'
const DELETE_SECTION = 'APP/DELETE_SECTION'
const DELETE_SECTION_SUCCESS = 'APP/DELETE_SECTION_SUCCESS'
const DELETE_SECTION_FAIL = 'APP/DELETE_SECTION_FAIL'
const FETCH_ALL_SECTIONS = 'APP/FETCH_ALL_SECTIONS'
const FETCH_ALL_SECTIONS_SUCCESS = 'APP/FETCH_ALL_SECTIONS_SUCCESS'
const FETCH_ALL_SECTIONS_FAIL = 'APP/FETCH_ALL_SECTIONS_FAIL'
const FETCH_SECTION = 'APP/FETCH_SECTION'
const FETCH_SECTION_SUCCESS = 'APP/FETCH_SECTION_SUCCESS'
const FETCH_SECTION_FAIL = 'APP/FETCH_SECTION_FAIL'
const EDIT_FETCHED_SECTION = 'APP/EDIT_FETCHED_SECTION'
const REPLACE_FETCHED_SECTION = 'APP/REPLACE_FETCHED_SECTION'
const FETCH_ALL_CONTENT = 'APP/FETCH_ALL_CONTENT'
const FETCH_ALL_CONTENT_SUCCESS = 'APP/FETCH_ALL_CONTENT_SUCCESS'
const FETCH_ALL_CONTENT_FAIL = 'APP/FETCH_ALL_CONTENT_FAIL'
const FETCH_CONTENT = 'APP/FETCH_CONTENT'
const FETCH_CONTENT_SUCCESS = 'APP/FETCH_CONTENT_SUCCESS'
const FETCH_CONTENT_FAIL = 'APP/FETCH_CONTENT_FAIL'
const CREATE_CONTENT = 'APP/CREATE_CONTENT'
const CREATE_CONTENT_SUCCESS = 'APP/CREATE_CONTENT_SUCCESS'
const CREATE_CONTENT_FAIL = 'APP/CREATE_CONTENT_FAIL'
const EDIT_CONTENT = 'APP/EDIT_CONTENT'
const EDIT_CONTENT_SUCCESS = 'APP/EDIT_CONTENT_SUCCESS'
const EDIT_CONTENT_FAIL = 'APP/EDIT_CONTENT_FAIL'
const DELETE_CONTENT = 'APP/DELETE_CONTENT'
const DELETE_CONTENT_SUCCESS = 'APP/DELETE_CONTENT_SUCCESS'
const DELETE_CONTENT_FAIL = 'APP/DELETE_CONTENT_FAIL'
const UPLOAD_FILE = 'APP/UPLOAD_FILE'
const UPLOAD_FILE_SUCCESS = 'APP/UPLOAD_FILE_SUCCESS'
const UPLOAD_FILE_FAIL = 'APP/UPLOAD_FILE_FAIL'
const CLEAR_CREATED_QUESTION = 'APP/CLEAR_CREATED_QUESTION'
const FETCH_ASSESSMENTS = 'APP/FETCH_ASSESSMENTS'
const FETCH_ASSESSMENTS_SUCCESS = 'APP/FETCH_ASSESSMENTS_SUCCESS'
const FETCH_ASSESSMENTS_FAIL = 'APP/FETCH_ASSESSMENTS_FAIL'
const CREATE_ASSESSMENT = 'APP/CREATE_ASSESSMENT'
const CREATE_ASSESSMENT_SUCCESS = 'APP/CREATE_ASSESSMENT_SUCCESS'
const CREATE_ASSESSMENT_FAIL = 'APP/CREATE_ASSESSMENT_FAIL'
const EDIT_ASSESSMENT = 'APP/EDIT_ASSESSMENT'
const EDIT_ASSESSMENT_SUCCESS = 'APP/EDIT_ASSESSMENT_SUCCESS'
const EDIT_ASSESSMENT_FAIL = 'APP/EDIT_ASSESSMENT_FAIL'
const DELETE_ASSESSMENT = 'APP/DELETE_ASSESSMENT'
const DELETE_ASSESSMENT_SUCCESS = 'APP/DELETE_ASSESSMENT_SUCCESS'
const DELETE_ASSESSMENT_FAIL = 'APP/DELETE_ASSESSMENT_FAIL'
const FETCH_PAYMENTS = 'APP/FETCH_PAYMENTS'
const FETCH_PAYMENTS_SUCCESS = 'APP/FETCH_PAYMENTS_SUCCESS'
const FETCH_PAYMENTS_FAIL = 'APP/FETCH_PAYMENTS_FAIL'
const CREATE_PAYMENT = 'APP/CREATE_PAYMENT'
const CREATE_PAYMENT_SUCCESS = 'APP/CREATE_PAYMENT_SUCCESS'
const CREATE_PAYMENT_FAIL = 'APP/CREATE_PAYMENT_FAIL'
const EDIT_PAYMENT = 'APP/EDIT_PAYMENT'
const EDIT_PAYMENT_SUCCESS = 'APP/EDIT_PAYMENT_SUCCESS'
const EDIT_PAYMENT_FAIL = 'APP/EDIT_PAYMENT_FAIL'
const DELETE_PAYMENT = 'APP/DELETE_PAYMENT'
const DELETE_PAYMENT_SUCCESS = 'APP/DELETE_PAYMENT_SUCCESS'
const DELETE_PAYMENT_FAIL = 'APP/DELETE_PAYMENT_FAIL'
const FETCH_SUBSCRIPTIONS = 'APP/FETCH_SUBSCRIPTIONS'
const FETCH_SUBSCRIPTIONS_SUCCESS = 'APP/FETCH_SUBSCRIPTIONS_SUCCESS'
const FETCH_SUBSCRIPTIONS_FAIL = 'APP/FETCH_SUBSCRIPTIONS_FAIL'
const CREATE_SUBSCRIPTION = 'APP/CREATE_SUBSCRIPTION'
const CREATE_SUBSCRIPTION_SUCCESS = 'APP/CREATE_SUBSCRIPTION_SUCCESS'
const CREATE_SUBSCRIPTION_FAIL = 'APP/CREATE_SUBSCRIPTION_FAIL'
const EDIT_SUBSCRIPTION = 'APP/EDIT_SUBSCRIPTION'
const EDIT_SUBSCRIPTION_SUCCESS = 'APP/EDIT_SUBSCRIPTION_SUCCESS'
const EDIT_SUBSCRIPTION_FAIL = 'APP/EDIT_SUBSCRIPTION_FAIL'
const DELETE_SUBSCRIPTION = 'APP/DELETE_SUBSCRIPTION'
const DELETE_SUBSCRIPTION_SUCCESS = 'APP/DELETE_SUBSCRIPTION_SUCCESS'
const DELETE_SUBSCRIPTION_FAIL = 'APP/DELETE_SUBSCRIPTION_FAIL'
const FETCH_USERS_STATISTICS = 'APP/FETCH_STATISTICS'
const FETCH_USERS_STATISTICS_SUCCESS = 'APP/FETCH_STATISTICS_SUCCESS'
const FETCH_USERS_STATISTICS_FAIL = 'APP/FETCH_USERS_STATISTICS_FAIL'
const FETCH_PAYMENTS_STATISTICS = 'APP/FETCH_PAYMENTS_STATISTICS'
const FETCH_PAYMENTS_STATISTICS_SUCCESS = 'APP/FETCH_PAYMENTS_STATISTICS_SUCCESS'
const FETCH_PAYMENTS_STATISTICS_FAIL = 'APP/FETCH_PAYMENTS_STATISTICS_FAIL'
const CREATE_USER = 'APP/CREATE_USER'
const CREATE_USER_SUCCESS = 'APP/CREATE_USER_SUCCESS'
const CREATE_USER_FAIL = 'APP/CREATE_USER_FAIL'

/* ------------- initial state ------------- */
const initialState = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  fetchCoursesLoading: false,
  createCourseLoading: false,
  editCourseLoading: false,
  deleteCourseLoading: false,
  fetchLevelsLoading: false,
  createLevelLoading: false,
  editLevelLoading: false,
  deleteLevelLoading: false,
  fetchUnitsLoading: false,
  createUnitLoading: false,
  editUnitLoading: false,
  deleteUnitLoading: false,
  fetchLessonsLoading: false,
  createLessonLoading: false,
  editLessonLoading: false,
  deleteLessonLoading: false,
  registerUserLoading: false,
  loginUserLoading: false,
  refreshUserTokenLoading: false,
  fetchUsersLoading: false,
  getUserInfoLoading: false,
  fetchUserSubscriptionsLoading: false,
  fetchUserPaymentsLoading: false,
  fetchQuestionsLoading: false,
  createQuestionLoading: false,
  editQuestionLoading: false,
  deleteQuestionLoading: false,
  fetchVideosLoading: false,
  createVideoLoading: false,
  createOfferLoading: false,
  editVideoLoading: false,
  editOfferLoading: false,
  deleteVideoLoading: false,
  deleteOfferLoading: false,
  fetchOffersLoading: false,
  fetchWordsLoading: false,
  createWordLoading: false,
  editWordLoading: false,
  deleteWordLoading: false,
  uploadCredentialsLoading: false,
  obtainOTPLoading: false,
  fetchAllSectionsLoading: false,
  fetchSectionLoading: false,
  createSectionLoading: false,
  editSectionLoading: false,
  fetchedSectionSuccess: false,
  deleteSectionLoading: false,
  fetchAllContentLoading: false,
  fetchContentLoading: false,
  createContentLoading: false,
  editContentLoading: false,
  deleteContentLoading: false,
  uploadFileLoading: false,
  fetchVideoByTitleLoading: false,
  fetchAssessmentsLoading: false,
  createAssessmentLoading: false,
  editAssessmentLoading: false,
  deleteAssessmentLoading: false,
  fetchPaymentsLoading: false,
  createPaymentLoading: false,
  editPaymentLoading: false,
  deletePaymentLoading: false,
  fetchSubscriptionsLoading: false,
  createSubscriptionLoading: false,
  editSubscriptionLoading: false,
  deleteSubscriptionLoading: false,
  fetchUsersStatisticsLoading: false,
  createUserLoading: false,
  courses: [],
  createdCourse: {},
  createdUser: {},
  editedCourse: {},
  createdLevel: {},
  editedLevel: {},
  createdUnit: {},
  editedUnit: {},
  createdLesson: {},
  editedLesson: {},
  registeredUser: {},
  user: {},
  users: [],
  userSubscriptions: [],
  userPayments: [],
  questions: [],
  videos: [],
  words: [],
  offers: [],
  createdWord: {},
  editedWord: {},
  uploadCredentials: {},
  otp: {},
  sections: [],
  fetchedSection: {},
  createdSection: {},
  editedSection: {},
  content: [],
  fetchedContent: {},
  createdContent: {},
  editedContent: {},
  loginUserError: '',
  registerUserError: '',
  uploadedFile: {},
  fetchedVideoByTitle: {},
  createdQuestion: {},
  editedQuestion: {},
  assessments: [],
  createdAssessment: {},
  editedAssessment: {},
  payments: [],
  createdPayment: {},
  editedPayment: {},
  subscriptions: [],
  createdSubscription: {},
  editedSubscription: {},
  usersStatistics: {},
  paymentsStatistics: {},
}

/* ------------- Reducer ------------- */
export const reducer = produce((state = initialState, action) => {
  switch (action.type) {
    case SET_SHOW_SIDEBAR:
      state.sidebarShow = action.payload
      return state
    case SET_UNFOLDABLE_SIDEBAR:
      state.sidebarUnfoldable = action.payload
      return state
    case FETCH_COURSES:
      state.fetchCoursesLoading = true
      return state
    case FETCH_COURSES_SUCCESS:
      state.courses = action.payload
      state.fetchCoursesLoading = false
      return state
    case FETCH_COURSES_FAIL:
      state.fetchCoursesLoading = false
      state.courses = []
      return state
    case CREATE_COURSE:
      state.createCourseLoading = true
      return state
    case CREATE_COURSE_SUCCESS:
      state.createCourseLoading = false
      state.createdCourse = action.payload
      return state
    case CREATE_COURSE_FAIL:
      state.fetchCoursesLoading = false
      state.createdCourse = {}
      return state
    case EDIT_COURSE:
      state.editCourseLoading = true
      return state
    case EDIT_COURSE_SUCCESS:
      state.editCourseLoading = false
      state.editedCourse = action.payload
      return state
    case EDIT_COURSE_FAIL:
      state.editCoursesLoading = false
      state.editedCourse = {}
      return state
    case DELETE_COURSE:
      state.deleteCourseLoading = true
      return state
    case DELETE_COURSE_SUCCESS:
      state.deleteCourseLoading = false
      return state
    case DELETE_COURSE_FAIL:
      state.deleteCourseLoading = false
      return state
    case CREATE_LEVEL:
      state.createLevelLoading = true
      return state
    case CREATE_LEVEL_SUCCESS:
      state.createLevelLoading = false
      state.createdLevel = action.payload
      return state
    case CREATE_LEVEL_FAIL:
      state.fetchLevelsLoading = false
      state.createdLevel = {}
      return state
    case EDIT_LEVEL:
      state.editLevelLoading = true
      return state
    case EDIT_LEVEL_SUCCESS:
      state.editLevelLoading = false
      state.editedLevel = action.payload
      return state
    case EDIT_LEVEL_FAIL:
      state.editLevelLoading = false
      state.editedLevel = {}
      return state
    case DELETE_LEVEL:
      state.deleteLevelLoading = true
      return state
    case DELETE_LEVEL_SUCCESS:
      state.deleteLevelLoading = false
      return state
    case DELETE_LEVEL_FAIL:
      state.deleteLevelLoading = false
      return state
    case CREATE_UNIT:
      state.createUnitLoading = true
      return state
    case CREATE_UNIT_SUCCESS:
      state.createUnitLoading = false
      state.createdUnit = action.payload
      return state
    case CREATE_UNIT_FAIL:
      state.fetchUnitsLoading = false
      state.createdUnit = {}
      return state
    case EDIT_UNIT:
      state.editUnitLoading = true
      return state
    case EDIT_UNIT_SUCCESS:
      state.editUnitLoading = false
      state.editedUnit = action.payload
      return state
    case EDIT_UNIT_FAIL:
      state.editUnitLoading = false
      state.editedUnit = {}
      return state
    case DELETE_UNIT:
      state.deleteUnitLoading = true
      return state
    case DELETE_UNIT_SUCCESS:
      state.deleteUnitLoading = false
      return state
    case DELETE_UNIT_FAIL:
      state.deleteUnitLoading = false
      return state
    case CREATE_LESSON:
      state.createLessonLoading = true
      return state
    case CREATE_LESSON_SUCCESS:
      state.createLessonLoading = false
      state.createdLesson = action.payload
      return state
    case CREATE_LESSON_FAIL:
      state.fetchLessonsLoading = false
      state.createdLesson = {}
      return state
    case EDIT_LESSON:
      state.editLessonLoading = true
      return state
    case EDIT_LESSON_SUCCESS:
      state.editLessonLoading = false
      state.editedLesson = action.payload
      return state
    case EDIT_LESSON_FAIL:
      state.editLessonLoading = false
      state.editedLesson = {}
      return state
    case DELETE_LESSON:
      state.deleteLessonLoading = true
      return state
    case DELETE_LESSON_SUCCESS:
      state.deleteLessonLoading = false
      return state
    case DELETE_LESSON_FAIL:
      state.deleteLessonLoading = false
      return state
    case REGISTER_USER:
      state.registerUserLoading = true
      return state
    case REGISTER_USER_SUCCESS:
      state.registerUserLoading = false
      state.registeredUser = action.payload
      return state
    case REGISTER_USER_FAIL:
      state.registerUserLoading = false
      state.registerUserError = action.payload
      return state
    case LOGIN_USER:
      state.loginUserLoading = true
      return state
    case LOGIN_USER_SUCCESS:
      state.loginUserLoading = false
      state.user = action.payload
      return state
    case LOGIN_USER_FAIL:
      state.loginUserLoading = false
      state.loginUserError = action.payload
      return state
    case REFRESH_USER_TOKEN:
      state.refreshUserLoading = true
      return state
    case REFRESH_USER_TOKEN_SUCCESS:
      state.refreshUserLoading = false
      state.user = action.payload
      return state
    case REFRESH_USER_TOKEN_FAIL:
      state.refreshUserLoading = false
      return state
    case GET_USER_INFO:
      state.getUserInfoLoading = true
      return state
    case GET_USER_INFO_SUCCESS:
      state.getUserInfoLoading = false
      state.user = action.payload
      return state
    case GET_USER_INFO_FAIL:
      state.getUserInfoLoading = false
      return state
    case FETCH_USERS:
      state.fetchUsersLoading = true
      return state
    case FETCH_USERS_SUCCESS:
      state.fetchUsersLoading = false
      state.users = action.payload
      return state
    case FETCH_USERS_FAIL:
      state.fetchUsersLoading = false
      return state
    case FETCH_USER_SUBSCRIPTIONS:
      state.fetchUserSubscriptionsLoading = true
      return state
    case FETCH_USER_SUBSCRIPTIONS_SUCCESS:
      state.fetchUserSubscriptionsLoading = false
      state.userSubscriptions = action.payload
      return state
    case FETCH_USER_SUBSCRIPTIONS_FAIL:
      state.fetchUserSubscriptionsLoading = false
      return state
    case FETCH_USER_PAYMENTS:
      state.fetchUserPaymentsLoading = true
      return state
    case FETCH_USER_PAYMENTS_SUCCESS:
      state.fetchUserPaymentsLoading = false
      state.userPayments = action.payload
      return state
    case FETCH_USER_PAYMENTS_FAIL:
      state.fetchUserPaymentsLoading = false
      return state
    case FETCH_QUESTIONS:
      state.fetchQuestionsLoading = true
      return state
    case FETCH_QUESTIONS_SUCCESS:
      state.fetchQuestionsLoading = false
      state.questions = action.payload
      return state
    case FETCH_QUESTIONS_FAIL:
      state.fetchQuestionsLoading = false
      return state
    case CREATE_QUESTION:
      state.createQuestionLoading = true
      return state
    case CREATE_QUESTION_SUCCESS:
      state.createQuestionLoading = false
      state.createdQuestion = action.payload
      return state
    case CREATE_QUESTION_FAIL:
      state.createQuestionLoading = false
      return state
    case EDIT_QUESTION:
      state.editQuestionLoading = true
      return state
    case EDIT_QUESTION_SUCCESS:
      state.editQuestionLoading = false
      state.editedQuestion = action.payload
      return state
    case EDIT_QUESTION_FAIL:
      state.editQuestionsLoading = false
      state.editedQuestion = {}
      return state
    case DELETE_QUESTION:
      state.deleteQuestionLoading = true
      return state
    case DELETE_QUESTION_SUCCESS:
      state.deleteQuestionLoading = false
      return state
    case DELETE_QUESTION_FAIL:
      state.deleteQuestionLoading = false
      return state
    case FETCH_VIDEOS:
      state.fetchVideosLoading = true
      return state
    case FETCH_VIDEOS_SUCCESS:
      state.videos = action.payload
      state.fetchVideosLoading = false
      return state
    case FETCH_VIDEOS_FAIL:
      state.fetchVideosLoading = false
      state.videos = []
      return state
    case FETCH_VIDEO_BY_TITLE:
      state.fetchVideoByTitleLoading = true
      return state
    case FETCH_VIDEO_BY_TITLE_SUCCESS:
      state.fetchedVideoByTitle = action.payload
      state.fetchVideoByTitleLoading = false
      return state
    case FETCH_VIDEO_BY_TITLE_FAIL:
      state.fetchVideoByTitleLoading = false
      state.fetchedVideoByTitle = {}
      return state
    case CREATE_VIDEO:
      state.createVideoLoading = true
      return state
    case CREATE_VIDEO_SUCCESS:
      state.createVideoLoading = false
      state.createdVideo = action.payload
      state.uploadCredentials = {}
      return state
    case CREATE_VIDEO_FAIL:
      state.createVideoLoading = false
      state.createdVideo = {}
      return state
    case EDIT_VIDEO:
      state.editVideoLoading = true
      return state
    case EDIT_VIDEO_SUCCESS:
      state.editVideoLoading = false
      state.editedVideo = action.payload
      return state
    case EDIT_VIDEO_FAIL:
      state.editVideosLoading = false
      state.editedVideo = {}
      return state
    case DELETE_VIDEO:
      state.deleteVideoLoading = true
      return state
    case DELETE_VIDEO_SUCCESS:
      state.deleteVideoLoading = false
      return state
    case DELETE_VIDEO_FAIL:
      state.deleteVideoLoading = false
      return state
    case FETCH_OFFERS:
      state.fetchOffersLoading = true
      return state
    case FETCH_OFFERS_SUCCESS:
      state.offers = action.payload
      state.fetchOffersLoading = false
      return state
    case FETCH_OFFERS_FAIL:
      state.fetchOffersLoading = false
      state.offers = []
      return state
    case CREATE_OFFER:
      state.createOfferLoading = true
      return state
    case CREATE_OFFER_SUCCESS:
      state.createOfferoLoading = false
      state.createdOffer = action.payload
      return state
    case CREATE_OFFER_FAIL:
      state.createOfferLoading = false
      state.createdOffer = {}
      return state
    case EDIT_OFFER:
      state.editOFFERLoading = true
      return state
    case EDIT_OFFER_SUCCESS:
      state.editOfferLoading = false
      state.editedVideo = action.payload
      return state
    case EDIT_OFFER_FAIL:
      state.editOfferLoading = false
      state.editedOffer = {}
      return state
    case DELETE_OFFER:
      state.deleteOfferLoading = true
      return state
    case DELETE_OFFER_SUCCESS:
      state.deleteOfferLoading = false
      return state
    case DELETE_OFFER_FAIL:
      state.deleteOfferLoading = false
      return state
    case FETCH_WORDS:
      state.fetchWordsLoading = true
      return state
    case FETCH_WORDS_SUCCESS:
      state.words = action.payload
      state.fetchWordsLoading = false
      return state
    case FETCH_WORDS_FAIL:
      state.fetchWordsLoading = false
      state.words = []
      return state
    case CHANGE_PASSWORD:
      state.changePasswordLoading = true
      return state
    case CHANGE_PASSWORD_SUCCESS:
      state.changePasswordLoading = false
      return state
    case CHANGE_PASSWORD_FAIL:
      state.changePasswordLoading = false
      return state
    case CREATE_WORD:
      state.createWordLoading = true
      return state
    case CREATE_WORD_SUCCESS:
      state.createWordLoading = false
      state.createdWord = action.payload
      return state
    case CREATE_WORD_FAIL:
      state.fetchWordsLoading = false
      state.createdWord = {}
      return state
    case EDIT_WORD:
      state.editWordLoading = true
      return state
    case EDIT_WORD_SUCCESS:
      state.editWordLoading = false
      state.editedWord = action.payload
      return state
    case EDIT_WORD_FAIL:
      state.editWordsLoading = false
      state.editedWord = {}
      return state
    case DELETE_WORD:
      state.deleteWordLoading = true
      return state
    case DELETE_WORD_SUCCESS:
      state.deleteWordLoading = false
      return state
    case DELETE_WORD_FAIL:
      state.deleteWordLoading = false
      return state
    case GET_UPLOAD_CREDENTIALS:
      state.uploadCredentialsLoading = true
      return state
    case GET_UPLOAD_CREDENTIALS_SUCCESS:
      state.uploadCredentialsLoading = false
      state.uploadCredentials = action.payload
      return state
    case GET_UPLOAD_CREDENTIALS_FAIL:
      state.uploadCredentialsLoading = false
      state.uploadCredentials = {}
      return state
    case OBTAIN_OTP:
      state.obtainOTPLoading = true
      return state
    case OBTAIN_OTP_SUCCESS:
      state.obtainOTPLoading = false
      state.otp = action.payload
      return state
    case OBTAIN_OTP_FAIL:
      state.obtainOTPLoading = false
      state.otp = {}
      return state

    case FETCH_ALL_SECTIONS:
      state.fetchAllSections = true
      return state
    case FETCH_ALL_SECTIONS_SUCCESS:
      state.sections = action.payload
      state.fetchAllSectionsLoading = false
      return state
    case FETCH_ALL_SECTIONS_FAIL:
      state.fetchAllSectionsLoading = false
      state.sections = []
      return state
    case FETCH_SECTION:
      state.fetchSectionLoading = true
      return state
    case FETCH_SECTION_SUCCESS:
      state.fetchedSection = action.payload
      state.fetchSectionLoading = false
      return state
    case FETCH_SECTION_FAIL:
      state.fetchSectionLoading = false
      state.fetchedSection = {}
      return state

    case EDIT_FETCHED_SECTION:
      // state.fetchedSection = action.payload
      state.fetchedSection = set(state.fetchedSection, action.payload.key, action.payload.newValue)
      return state

    case REPLACE_FETCHED_SECTION:
      state.fetchedSection = action.payload
      return state

    case CREATE_SECTION:
      state.createSectionLoading = true
      state.fetchedSectionSuccess = false
      return state
    case CREATE_SECTION_SUCCESS:
      state.createSectionLoading = false
      state.fetchedSectionSuccess = true
      state.createdSection = action.payload
      return state
    case CREATE_SECTION_FAIL:
      state.fetchSectionsLoading = false
      state.fetchedSectionSuccess = false
      state.createdSection = {}
      return state
    case EDIT_SECTION:
      state.editSectionLoading = true
      state.fetchedSectionSuccess = false
      return state
    case EDIT_SECTION_SUCCESS:
      state.editSectionLoading = false
      state.fetchedSectionSuccess = true
      state.editedSection = action.payload
      return state
    case EDIT_SECTION_FAIL:
      state.editSectionLoading = false
      state.fetchedSectionSuccess = false
      state.editedSection = {}
      return state
    case DELETE_SECTION:
      state.deleteSectionLoading = true
      return state
    case DELETE_SECTION_SUCCESS:
      state.deleteSectionLoading = false
      return state
    case DELETE_SECTION_FAIL:
      state.deleteSectionLoading = false
      return state
    case FETCH_ALL_CONTENT:
      state.fetchAllContentLoading = true
      return state
    case FETCH_ALL_CONTENT_SUCCESS:
      state.content = action.payload
      state.fetchAllContentLoading = false
      return state
    case FETCH_ALL_CONTENT_FAIL:
      state.fetchAllContentLoading = false
      state.content = []
      return state
    case FETCH_CONTENT:
      state.fetchContentLoading = true
      return state
    case FETCH_CONTENT_SUCCESS:
      state.fetchedContent = action.payload
      state.fetchContentLoading = false
      return state
    case FETCH_CONTENT_FAIL:
      state.fetchContentLoading = false
      state.fetchedContent = []
      return state
    case CREATE_CONTENT:
      state.createContentLoading = true
      return state
    case CREATE_CONTENT_SUCCESS:
      state.createContentLoading = false
      state.createdContent = action.payload
      return state
    case CREATE_CONTENT_FAIL:
      state.fetchContentsLoading = false
      state.createdContent = {}
      return state
    case CREATE_USER:
      state.createUserLoading = true
      return state
    case CREATE_USER_SUCCESS:
      state.createUserLoading = false
      state.createdUser = action.payload
      return state
    case CREATE_USER_FAIL:
      state.createUserLoading = false
      state.createdUser = {}
      return state
    case EDIT_CONTENT:
      state.editContentLoading = true
      return state
    case EDIT_CONTENT_SUCCESS:
      state.editContentLoading = false
      state.editedContent = action.payload
      return state
    case EDIT_CONTENT_FAIL:
      state.editContentsLoading = false
      state.editedContent = {}
      return state
    case DELETE_CONTENT:
      state.deleteContentLoading = true
      return state
    case DELETE_CONTENT_SUCCESS:
      state.deleteContentLoading = false
      return state
    case DELETE_CONTENT_FAIL:
      state.deleteContentLoading = false
      return state
    case UPLOAD_FILE:
      state.uploadFileLoading = true
      return state
    case UPLOAD_FILE_SUCCESS:
      state.uploadFileLoading = false
      state.uploadedFile = action.payload
      return state
    case UPLOAD_FILE_FAIL:
      state.uploadFileLoading = false
      return state
    case CLEAR_CREATED_QUESTION:
      state.createdQuestion = undefined
      return state
    case FETCH_ASSESSMENTS:
      state.fetchAssessmentsLoading = true
      return state
    case FETCH_ASSESSMENTS_SUCCESS:
      state.assessments = action.payload
      state.fetchAssessmentsLoading = false
      return state
    case FETCH_ASSESSMENTS_FAIL:
      state.fetchAssessmentsLoading = false
      state.assessments = []
      return state
    case CREATE_ASSESSMENT:
      state.createAssessmentLoading = true
      return state
    case CREATE_ASSESSMENT_SUCCESS:
      state.createAssessmentLoading = false
      state.createdAssessment = action.payload
      return state
    case CREATE_ASSESSMENT_FAIL:
      state.fetchAssessmentsLoading = false
      state.createdAssessment = {}
      return state
    case EDIT_ASSESSMENT:
      state.editAssessmentLoading = true
      return state
    case EDIT_ASSESSMENT_SUCCESS:
      state.editAssessmentLoading = false
      state.editedAssessment = action.payload
      return state
    case EDIT_ASSESSMENT_FAIL:
      state.editAssessmentsLoading = false
      state.editedAssessment = {}
      return state
    case DELETE_ASSESSMENT:
      state.deleteAssessmentLoading = true
      return state
    case DELETE_ASSESSMENT_SUCCESS:
      state.deleteAssessmentLoading = false
      return state
    case DELETE_ASSESSMENT_FAIL:
      state.deleteAssessmentLoading = false
      return state
    case FETCH_PAYMENTS:
      state.fetchPaymentsLoading = true
      return state
    case FETCH_PAYMENTS_SUCCESS:
      state.payments = action.payload
      state.fetchPaymentsLoading = false
      return state
    case FETCH_PAYMENTS_FAIL:
      state.fetchPaymentsLoading = false
      state.payments = []
      return state
    case CREATE_PAYMENT:
      state.createPaymentLoading = true
      return state
    case CREATE_PAYMENT_SUCCESS:
      state.createPaymentLoading = false
      state.createdPayment = action.payload
      return state
    case CREATE_PAYMENT_FAIL:
      state.fetchPaymentsLoading = false
      state.createdPayment = {}
      return state
    case EDIT_PAYMENT:
      state.editPaymentLoading = true
      return state
    case EDIT_PAYMENT_SUCCESS:
      state.editPaymentLoading = false
      state.editedPayment = action.payload
      return state
    case EDIT_PAYMENT_FAIL:
      state.editPaymentsLoading = false
      state.editedPayment = {}
      return state
    case DELETE_PAYMENT:
      state.deletePaymentLoading = true
      return state
    case DELETE_PAYMENT_SUCCESS:
      state.deletePaymentLoading = false
      return state
    case DELETE_PAYMENT_FAIL:
      state.deletePaymentLoading = false
      return state
    case FETCH_SUBSCRIPTIONS:
      state.fetchSubscriptionsLoading = true
      return state
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      state.subscriptions = action.payload
      state.fetchSubscriptionsLoading = false
      return state
    case FETCH_SUBSCRIPTIONS_FAIL:
      state.fetchSubscriptionsLoading = false
      state.subscriptions = []
      return state
    case CREATE_SUBSCRIPTION:
      state.createSubscriptionLoading = true
      return state
    case CREATE_SUBSCRIPTION_SUCCESS:
      state.createSubscriptionLoading = false
      state.createdSubscription = action.payload
      return state
    case CREATE_SUBSCRIPTION_FAIL:
      state.fetchSubscriptionsLoading = false
      state.createdSubscription = {}
      return state
    case EDIT_SUBSCRIPTION:
      state.editSubscriptionLoading = true
      return state
    case EDIT_SUBSCRIPTION_SUCCESS:
      state.editSubscriptionLoading = false
      state.editedSubscription = action.payload
      return state
    case EDIT_SUBSCRIPTION_FAIL:
      state.editSubscriptionsLoading = false
      state.editedSubscription = {}
      return state
    case DELETE_SUBSCRIPTION:
      state.deleteSubscriptionLoading = true
      return state
    case DELETE_SUBSCRIPTION_SUCCESS:
      state.deleteSubscriptionLoading = false
      return state
    case DELETE_SUBSCRIPTION_FAIL:
      state.deleteSubscriptionLoading = false
      return state
    case FETCH_USERS_STATISTICS:
      state.fetchUsersStatisticsLoading = true
      return state
    case FETCH_USERS_STATISTICS_SUCCESS:
      state.fetchUsersStatisticsLoading = false
      state.usersStatistics = action.payload
      return state
    case FETCH_USERS_STATISTICS_FAIL:
      state.fetchUsersStatisticsLoading = false
      return state
    case FETCH_PAYMENTS_STATISTICS:
      state.fetchPaymentStatisticsLoading = true
      return state
    case FETCH_PAYMENTS_STATISTICS_SUCCESS:
      state.fetchPaymentsStatisticsLoading = false
      state.paymentsStatistics = action.payload
      return state
    case FETCH_PAYMENTS_STATISTICS_FAIL:
      state.fetchPaymentsStatisticsLoading = false
      return state
    default:
      return state
  }
})

/* ------------- Action Creators ------------- */
export function setSidebarShow(show) {
  return { type: SET_SHOW_SIDEBAR, payload: show }
}
export function setSidebarUnfoldable(foldable) {
  return { type: SET_UNFOLDABLE_SIDEBAR, payload: foldable }
}

export const fetchCourses = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchCoursesLoading())
  axios
    .get(`${api}/courses/`, config)
    .then((res) => {
      const courses = res.data
      dispatch(fetchCoursesSuccess(courses))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchCoursesFail())
    })
}

export function fetchCoursesLoading() {
  return { type: FETCH_COURSES }
}

export function fetchCoursesSuccess(courses) {
  return { type: FETCH_COURSES_SUCCESS, payload: courses }
}

export function fetchCoursesFail() {
  return { type: FETCH_COURSES_FAIL }
}

export const createCourse = (course) => async (dispatch, getState) => {
  const body = {
    ...course,
  }
  axios
    .post(`${api}/courses/`, body, config)
    .then((res) => {
      const course = res.data
      dispatch(createCourseSuccess(course))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createCourseFail())
    })
}

export function createCourseSuccess(course) {
  return { type: CREATE_COURSE_SUCCESS, payload: course }
}

export function createCourseFail() {
  return { type: CREATE_COURSE_FAIL }
}

export const editCourse = (course) => async (dispatch, getState) => {
  const bodyClone = { ...course }
  console.log(bodyClone)
  delete bodyClone.levels
  if (bodyClone.cover && bodyClone.cover.length < 2) {
    delete bodyClone.cover
  }
  if (bodyClone.createdAt) {
    delete bodyClone.createdAt
  }
  console.log(bodyClone)
  const body = {
    ...bodyClone,
  }
  axios
    .put(`${api}/courses/${course.id}`, body, config)
    .then((res) => {
      const course = res.data
      dispatch(editCourseSuccess(course))
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editCourseFail())
    })
}

export function editCourseSuccess(course) {
  return { type: EDIT_COURSE_SUCCESS, payload: course }
}

export function editCourseFail(errorMessage) {
  return { type: EDIT_COURSE_FAIL, payload: errorMessage }
}

export const deleteCourse = (course) => async (dispatch, getState) => {
  axios
    .delete(`${api}/courses/${course.id}`, config)
    .then((res) => {
      dispatch(deleteCourseSuccess())
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteCourseFail())
    })
}

export function deleteCourseSuccess() {
  return { type: DELETE_COURSE_SUCCESS }
}

export function deleteCourseFail() {
  return { type: DELETE_COURSE_FAIL }
}

export const createLevel = (level) => async (dispatch, getState) => {
  const body = {
    ...level,
  }
  axios
    .post(`${api}/levels/`, body, config)
    .then((res) => {
      const level = res.data
      dispatch(createLevelSuccess(level))
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(createLevelFail())
    })
}

export function createLevelSuccess(level) {
  return { type: CREATE_LEVEL_SUCCESS, payload: level }
}

export function createLevelFail() {
  return { type: CREATE_LEVEL_FAIL }
}

export const editLevel = (level) => async (dispatch, getState) => {
  const cloneLevel = { ...level }
  delete cloneLevel.units
  if (cloneLevel.createdAt) {
    delete cloneLevel.createdAt
  }
  const body = {
    ...cloneLevel,
  }
  console.log(body)
  axios
    .put(`${api}/levels/${level.id}`, body, config)
    .then((res) => {
      const level = res.data
      dispatch(editLevelSuccess(level))
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editLevelFail(e))
    })
}

export function editLevelSuccess(level) {
  return { type: EDIT_LEVEL_SUCCESS, payload: level }
}

export function editLevelFail() {
  return { type: EDIT_LEVEL_FAIL }
}

export const deleteLevel = (level) => async (dispatch, getState) => {
  axios
    .delete(`${api}/levels/${level.id}`, config)
    .then((res) => {
      dispatch(deleteLevelSuccess())
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteLevelFail())
    })
}

export function deleteLevelSuccess() {
  return { type: DELETE_LEVEL_SUCCESS }
}

export function deleteLevelFail() {
  return { type: DELETE_LEVEL_FAIL }
}

export const createUnit = (unit) => async (dispatch, getState) => {
  const body = {
    ...unit,
  }
  axios
    .post(`${api}/units/`, body, config)
    .then((res) => {
      const unit = res.data
      dispatch(createUnitSuccess(unit))
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(createUnitFail())
    })
}

export function createUnitSuccess(unit) {
  return { type: CREATE_UNIT_SUCCESS, payload: unit }
}

export function createUnitFail() {
  return { type: CREATE_UNIT_FAIL }
}

export const editUnit = (unit) => async (dispatch, getState) => {
  const cloneUnit = { ...unit }
  delete cloneUnit.lessons
  if (cloneUnit.createdAt) {
    delete cloneUnit.createdAt
  }
  const body = {
    ...cloneUnit,
  }
  axios
    .put(`${api}/units/${unit.id}`, body, config)
    .then((res) => {
      const unit = res.data
      dispatch(editUnitSuccess(unit))
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editUnitFail())
    })
}

export function editUnitSuccess(unit) {
  return { type: EDIT_UNIT_SUCCESS, payload: unit }
}

export function editUnitFail() {
  return { type: EDIT_UNIT_FAIL }
}

export const deleteUnit = (unit) => async (dispatch, getState) => {
  axios
    .delete(`${api}/units/${unit.id}`, config)
    .then((res) => {
      dispatch(deleteUnitSuccess())
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteUnitFail())
    })
}

export function deleteUnitSuccess() {
  return { type: DELETE_UNIT_SUCCESS }
}

export function deleteUnitFail() {
  return { type: DELETE_UNIT_FAIL }
}

export const createLesson = (lesson) => async (dispatch, getState) => {
  const body = {
    ...lesson,
  }
  axios
    .post(`${api}/lessons/`, body, config)
    .then((res) => {
      const lesson = res.data
      dispatch(createLessonSuccess(lesson))
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(createLessonFail())
    })
}

export function createLessonSuccess(lesson) {
  return { type: CREATE_LESSON_SUCCESS, payload: lesson }
}

export function createLessonFail() {
  return { type: CREATE_LESSON_FAIL }
}

export const editLesson = (lesson) => async (dispatch, getState) => {
  const cloneLesson = { ...lesson }
  delete cloneLesson.sections
  if (cloneLesson.createdAt) {
    delete cloneLesson.createdAt
  }
  const body = {
    ...cloneLesson,
  }
  axios
    .put(`${api}/lessons/${lesson.id}`, body, config)
    .then((res) => {
      const lesson = res.data
      dispatch(editLessonSuccess(lesson))
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editLessonFail())
    })
}

export function editLessonSuccess(lesson) {
  return { type: EDIT_LESSON_SUCCESS, payload: lesson }
}

export function editLessonFail() {
  return { type: EDIT_LESSON_FAIL }
}

export const deleteLesson = (lesson) => async (dispatch, getState) => {
  axios
    .delete(`${api}/lessons/${lesson.id}`, config)
    .then((res) => {
      dispatch(deleteLessonSuccess())
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteLessonFail())
    })
}

export function deleteLessonSuccess() {
  return { type: DELETE_LESSON_SUCCESS }
}

export function deleteLessonFail() {
  return { type: DELETE_LESSON_FAIL }
}

export const registerUser = (user) => async (dispatch, getState) => {
  const body = {
    ...user,
  }
  axios
    .post(`${api}/auth/register`, body, config)
    .then((res) => {
      const user = res.data
      dispatch(registerUserSuccess(user))
      dispatch(loginUser({ email: body.email, password: body.password }))
    })
    .catch((e) => {
      dispatch(registerUserFail(e.response.data.message))
    })
}

export function registerUserSuccess(user) {
  return { type: REGISTER_USER_SUCCESS, payload: user }
}

export function registerUserFail(err) {
  return { type: REGISTER_USER_FAIL, payload: err }
}

export const loginUser = (user) => async (dispatch, getState) => {
  const body = {
    ...user,
  }
  axios
    .post(`${api}/auth/login`, body, config)
    .then((res) => {
      const user = res.data
      if (user && user.user?.id && user.tokens?.access?.token) {
        sessionStorage.setItem('id', user.user?.id)
        sessionStorage.setItem('role', user.user?.role)
        sessionStorage.setItem('accessToken', user.tokens?.access?.token)
        sessionStorage.setItem('accessTokenExpires', user.tokens?.access?.expires)
        sessionStorage.setItem('refreshToken', user.tokens?.refresh?.token)
        sessionStorage.setItem('refreshTokenExpires', user.tokens?.refresh?.expires)
        token = user.tokens?.access?.token
        config.headers.Authorization = `Bearer ${user.tokens?.access?.token}`
      } else throw Error('User is Invalid!')
      dispatch(loginUserSuccess(user.user))
    })
    .catch((e) => {
      console.log(e)
      dispatch(loginUserFail(e.response.data.message))
    })
}

export function loginUserSuccess(user) {
  return { type: LOGIN_USER_SUCCESS, payload: user }
}

export function loginUserFail(err) {
  return { type: LOGIN_USER_FAIL, payload: err }
}

export const refreshUserToken = (refreshToken) => async (dispatch, getState) => {
  const body = {
    refreshToken,
  }
  axios
    .post(`${api}/auth/refresh-tokens`, body, config)
    .then((res) => {
      const tokens = res.data
      sessionStorage.setItem('accessToken', tokens.access?.token)
      sessionStorage.setItem('accessTokenExpires', tokens?.access?.expires)
      sessionStorage.setItem('refreshToken', tokens?.refresh?.token)
      sessionStorage.setItem('refreshTokenExpires', tokens?.refresh?.expires)
      dispatch(refreshUserTokenSuccess(tokens))
    })
    .catch((e) => {
      console.log(e)
      dispatch(refreshUserTokenFail())
    })
}

export function refreshUserTokenSuccess(tokens) {
  return { type: REFRESH_USER_TOKEN_SUCCESS, payload: tokens }
}

export function refreshUserTokenFail() {
  return { type: REFRESH_USER_TOKEN_FAIL }
}

export const fetchUsers =
  (role = null, excludeRoles = null, projection = null, isCurrentMonth = null) =>
  async (dispatch, getState) => {
    dispatch(fetchUsersLoading())

    const queryParams = new URLSearchParams()
    if (role) queryParams.append('role', role)
    if (excludeRoles && excludeRoles.length > 0)
      queryParams.append('excludeRoles', encodeURIComponent(JSON.stringify(excludeRoles)))
    if (projection) {
      queryParams.append('projection', encodeURIComponent(JSON.stringify(projection)))
    }
    if (isCurrentMonth) {
      queryParams.append('isCurrentMonth', encodeURIComponent(JSON.stringify(isCurrentMonth)))
    }

    await axios
      .get(`${api}/users/?${queryParams}`, config)
      .then((res) => {
        const users = res.data.results
        dispatch(fetchUsersSuccess(users))
      })
      .catch((e) => {
        console.log(e)
        dispatch(fetchUsersFail())
      })
  }

export function fetchUsersLoading() {
  return { type: FETCH_USERS }
}

export function fetchUsersSuccess(users) {
  return { type: FETCH_USERS_SUCCESS, payload: users }
}

export function fetchUsersFail() {
  return { type: FETCH_USERS_FAIL }
}

export const getUserInfo = (userId) => async (dispatch, getState) => {
  dispatch(getUserInfoLoading())
  axios
    .get(`${api}/users/${userId}`, config)
    .then((res) => {
      const user = res.data
      dispatch(getUserInfoSuccess(user))
    })
    .catch((e) => {
      console.log(e)
      dispatch(getUserInfoFail())
    })
}

export function getUserInfoLoading() {
  return { type: GET_USER_INFO }
}

export function getUserInfoSuccess(user) {
  return { type: GET_USER_INFO_SUCCESS, payload: user }
}

export function getUserInfoFail() {
  return { type: GET_USER_INFO_FAIL }
}

export const fetchUserSubscriptions = (user) => async (dispatch, getState) => {
  dispatch(fetchUserSubscriptionsLoading())
  axios
    .get(`${api}/subscriptions?user=${user}`, config)
    .then((res) => {
      const subscriptions = res.data.results
      dispatch(fetchUserSubscriptionsSuccess(subscriptions))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchUserSubscriptionsFail())
    })
}

export function fetchUserSubscriptionsLoading() {
  return { type: FETCH_USER_SUBSCRIPTIONS }
}

export function fetchUserSubscriptionsSuccess(subscriptions) {
  return { type: FETCH_USER_SUBSCRIPTIONS_SUCCESS, payload: subscriptions }
}

export function fetchUserSubscriptionsFail() {
  return { type: FETCH_USER_SUBSCRIPTIONS_FAIL }
}

export const fetchUserPayments = (user) => async (dispatch, getState) => {
  dispatch(fetchUserPaymentsLoading())
  axios
    .get(`${api}/payments?user=${user}`, config)
    .then((res) => {
      const payments = res.data
      dispatch(fetchUserPaymentsSuccess(payments))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchUserPaymentsFail())
    })
}

export function fetchUserPaymentsLoading() {
  return { type: FETCH_USER_PAYMENTS }
}

export function fetchUserPaymentsSuccess(payments) {
  return { type: FETCH_USER_PAYMENTS_SUCCESS, payload: payments }
}

export function fetchUserPaymentsFail() {
  return { type: FETCH_USER_PAYMENTS_FAIL }
}

export const fetchQuestions = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchQuestionsLoading())
  axios
    .get(`${api}/questions?limit=${10000}&page=${1}/`, config)
    .then((res) => {
      const questions = res.data
      dispatch(fetchQuestionsSuccess(questions))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchQuestionsFail())
    })
}

export function fetchQuestionsLoading() {
  return { type: FETCH_QUESTIONS }
}

export function fetchQuestionsSuccess(questions) {
  return { type: FETCH_QUESTIONS_SUCCESS, payload: questions }
}

export function fetchQuestionsFail() {
  return { type: FETCH_QUESTIONS_FAIL }
}

export function clearCreatedQuestion() {
  return { type: CLEAR_CREATED_QUESTION }
}

export const createQuestion = (question) => async (dispatch, getState) => {
  const body = {}
  // switch (question.type) {
  //   case 'mcq':
  //     Object.assign(body, question)
  //     break
  //   case 'textQuestion':
  //     Object.assign(body, question)
  //     break
  //   case 'gapFilling':
  //     question.gaps = question.text.split(' ').reduce((acc, word, index) => {
  //       if (word === '""') acc.push(index)
  //       return acc
  //     }, [])
  //     question.modelAnswer = question.modelAnswer ? question.modelAnswer.split(',') : []
  //     Object.assign(body, question)
  //     break
  //   case 'matching':
  //     question.column1 = question.column1.split('\n')
  //     question.column2 = question.column2.split('\n')
  //     question.modelRelations = question.modelRelations
  //       ? question.modelRelations.split('\n').reduce((acc, line) => {
  //           const keyArrays = line.split('=>')
  //           acc[keyArrays[0]] = keyArrays[1]
  //           return acc
  //         }, {})
  //       : {}
  //     Object.assign(body, question)
  //     break
  //   case 'trueOrFalse':
  //     Object.assign(body, {
  //       ...question,
  //       modelAnswer: question.modelAnswer === 'true' ? true : false,
  //     })
  //     break
  //   case 'tableCompletion':
  //     // question.columns = question.columns.split(',')
  //     // question.rows = question.rows.split('\n')
  //     // question.gaps = question.rows.reduce((acc, line, row) => {
  //     //   const words = line.split(',')
  //     //   words.forEach((word, column) => {
  //     //     if (word === '""') acc.push([row, column])
  //     //   })
  //     //   return acc
  //     // }, [])
  //     // question.modelAnswer = question.modelAnswer ? question.modelAnswer.split(',') : []
  //     Object.assign(body, question)
  //     break
  //   case 'rearrange':
  //     Object.assign(body, question)
  //     break
  //   case 'stylingWords':
  //     question.modelAnswer = question.modelAnswer ? question.modelAnswer.split(',') : []
  //     Object.assign(body, question)
  //     break
  //   default:
  //     break
  // }
  Object.assign(body, question)
  axios
    .post(`${api}/questions/`, body, config)
    .then((res) => {
      const question = res.data
      dispatch(createQuestionSuccess(question))
      // dispatch(fetchQuestions())
    })
    .catch((e) => {
      console.log(e)
      dispatch(createQuestionFail())
    })
}

export function createQuestionSuccess(question) {
  return { type: CREATE_QUESTION_SUCCESS, payload: question }
}

export function createQuestionFail() {
  return { type: CREATE_QUESTION_FAIL }
}

export const editQuestion = (question) => async (dispatch, getState) => {
  const body = {}
  // switch (question.type) {
  //   case 'mcq':
  //     if (!Array.isArray(question.choices)) question.choices = question.choices.split('.,')
  //     if (question.correctChoices && !Array.isArray(question.correctChoices))
  //       question.correctChoices = question.correctChoices.split('.,')
  //     Object.assign(body, question)
  //     break
  //   case 'textQuestion':
  //     Object.assign(body, question)
  //     break
  //   case 'gapFilling':
  //     question.gaps = question.text.split(' ').reduce((acc, word, index) => {
  //       if (word === '""') acc.push(index)
  //       return acc
  //     }, [])
  //     // question.modelAnswer =
  //     //   question.modelAnswer && !Array.isArray(question.modelAnswer)
  //     //     ? question.modelAnswer.split(',')
  //     //     : []
  //     Object.assign(body, question)
  //     break
  //   case 'matching':
  //     // question.column1 = question.column1.split('\n')
  //     // question.column2 = question.column2.split('\n')
  //     // question.modelRelations = question.modelRelations
  //     //   ? question.modelRelations.split('\n').reduce((acc, line) => {
  //     //       const keyArrays = line.split('=>')
  //     //       acc[keyArrays[0]] = keyArrays[1]
  //     //       return acc
  //     //     }, {})
  //     //   : {}
  //     Object.assign(body, question)
  //     break
  //   case 'trueOrFalse':
  //     Object.assign(body, question)
  //     break
  //   case 'tableCompletion':
  //     // question.columns = question.columns.split(',')
  //     // question.rows = question.rows.split('\n')
  //     // question.gaps = question.rows.reduce((acc, line, row) => {
  //     //   const words = line.split(',')
  //     //   words.forEach((word, column) => {
  //     //     if (word === '""') acc.push([row, column])
  //     //   })
  //     //   return acc
  //     // }, [])
  //     // question.modelAnswer = question.modelAnswer ? question.modelAnswer.split(',') : []
  //     Object.assign(body, question)
  //     break
  //   case 'rearrange':
  //     Object.assign(body, question)
  //     break
  //   case 'stylingWords':
  //     question.modelAnswer = question.modelAnswer ? question.modelAnswer.split(',') : []
  //     Object.assign(body, question)
  //     break
  //   default:
  //     break
  // }
  Object.assign(body, question)
  delete body.id
  delete body._id
  delete body.__t
  if (body.createdAt) {
    delete body.createdAt
  }

  axios
    .put(`${api}/questions/${question.id}`, body, config)
    .then((res) => {
      const question = res.data
      dispatch(editQuestionSuccess(question))
      // dispatch(fetchQuestions())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editQuestionFail())
    })
}

export function editQuestionSuccess(question) {
  return { type: EDIT_QUESTION_SUCCESS, payload: question }
}

export function editQuestionFail() {
  return { type: EDIT_QUESTION_FAIL }
}

export const deleteQuestion = (question) => async (dispatch, getState) => {
  axios
    .delete(`${api}/questions/${question.id}`, config)
    .then((res) => {
      dispatch(deleteQuestionSuccess())
      dispatch(fetchQuestions())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteQuestionFail())
    })
}

export function deleteQuestionSuccess() {
  return { type: DELETE_QUESTION_SUCCESS }
}

export function deleteQuestionFail() {
  return { type: DELETE_QUESTION_FAIL }
}

export const fetchVideos = (limit, page) => async (dispatch, getState) => {
  dispatch(fetchVideosLoading())
  axios
    .get(`${api}/videos?limit=${10000}&page=${1}`, config)
    .then((res) => {
      const videos = res.data
      dispatch(fetchVideosSuccess(videos))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchVideosFail())
    })
}
export const fetchOffers =
  (limit = 10000, page = 1) =>
  async (dispatch, getState) => {
    dispatch(fetchOffersLoading())
    axios
      .get(`${api}/offer?limit=${limit}&page=${page}`, config)
      .then((res) => {
        const offers = res.data.results
        dispatch(fetchOffersSuccess(offers))
      })
      .catch((e) => {
        console.log(e)
        dispatch(fetchOffersFail())
      })
  }

export function fetchOffersLoading() {
  return { type: FETCH_OFFERS }
}

export function fetchOffersSuccess(offers) {
  return { type: FETCH_OFFERS_SUCCESS, payload: offers }
}

export function fetchOffersFail() {
  return { type: FETCH_OFFERS_FAIL }
}

export function fetchVideosLoading() {
  return { type: FETCH_VIDEOS }
}

export function fetchVideosSuccess(videos) {
  return { type: FETCH_VIDEOS_SUCCESS, payload: videos }
}

export function fetchVideosFail() {
  return { type: FETCH_VIDEOS_FAIL }
}

export const fetchVideoByTitle = (title) => async (dispatch, getState) => {
  dispatch(fetchVideoByTitleLoading())
  axios
    .get(`${api}/videos?title=${title}`, config)
    .then((res) => {
      const video = res.data.results[0]
      dispatch(fetchVideoByTitleSuccess(video))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchVideoByTitleFail())
    })
}

export function fetchVideoByTitleLoading() {
  return { type: FETCH_VIDEO_BY_TITLE }
}

export function fetchVideoByTitleSuccess(video) {
  return { type: FETCH_VIDEO_BY_TITLE_SUCCESS, payload: video }
}

export function fetchVideoByTitleFail() {
  return { type: FETCH_VIDEO_BY_TITLE_FAIL }
}

export const createVideo = (video) => async (dispatch, getState) => {
  const body = {
    ...video,
  }
  axios
    .post(`${api}/videos/`, body, config)
    .then((res) => {
      const video = res.data
      dispatch(createVideoSuccess(video))
      dispatch(fetchVideos())
    })
    .catch((e) => {
      console.log(e)
      dispatch(createVideoFail())
    })
}

export const createOffer = (offer) => async (dispatch, getState) => {
  const body = {
    ...offer,
  }

  axios
    .post(`${api}/offer`, body, config)
    .then((res) => {
      const offer = res.data
      dispatch(createOfferSuccess(offer))
      dispatch(fetchOffers())
    })
    .catch((e) => {
      console.log(e)
      dispatch(createOfferFail())
    })
}

export function createOfferSuccess(offer) {
  return { type: CREATE_OFFER_SUCCESS, payload: offer }
}

export function createOfferFail() {
  return { type: CREATE_OFFER_FAIL }
}

export function createVideoSuccess(video) {
  return { type: CREATE_VIDEO_SUCCESS, payload: video }
}

export function createVideoFail() {
  return { type: CREATE_VIDEO_FAIL }
}

export const editVideo = (video) => async (dispatch, getState) => {
  const body = {
    subtitles: video.subtitles,
    pauses: video.pauses,
    title: video.title,
    type: video.type,
    url: video.url,
    vdoCipherId: video.vdoCipherId,
  }
  axios
    .put(`${api}/videos/${video.id}`, body, config)
    .then((res) => {
      const video = res.data
      dispatch(editVideoSuccess(video))
      dispatch(fetchVideos())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editVideoFail())
    })
}
export const editOffer = (offer) => async (dispatch, getState) => {
  //check
  delete offer.id
  if (offer.createdAt) {
    delete offer.createdAt
  }
  axios
    .put(`${api}/offer/${offer.id}`, offer, config)
    .then((res) => {
      const offer = res.data
      dispatch(editOfferSuccess(offer))
      dispatch(fetchOffers())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editOfferFail())
    })
}

export function editOfferSuccess(video) {
  return { type: EDIT_OFFER_SUCCESS, payload: video }
}

export function editOfferFail() {
  return { type: EDIT_OFFER_FAIL }
}

export function editVideoSuccess(video) {
  return { type: EDIT_VIDEO_SUCCESS, payload: video }
}

export function editVideoFail() {
  return { type: EDIT_VIDEO_FAIL }
}

export const deleteVideo = (video) => async (dispatch, getState) => {
  axios
    .delete(`${api}/videos/${video.id}`, config)
    .then((res) => {
      dispatch(deleteVideoSuccess())
      dispatch(fetchVideos())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteVideoFail())
    })
}

export const deleteOffer = (offer) => async (dispatch, getState) => {
  axios
    .delete(`${api}/offer/${offer.id}`, config)
    .then((res) => {
      dispatch(deleteOfferSuccess())
      dispatch(fetchOffers())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteOfferFail())
    })
}

export function deleteOfferSuccess() {
  return { type: DELETE_OFFER_SUCCESS }
}

export function deleteOfferFail() {
  return { type: DELETE_OFFER_FAIL }
}
export function deleteVideoSuccess() {
  return { type: DELETE_VIDEO_SUCCESS }
}

export function deleteVideoFail() {
  return { type: DELETE_VIDEO_FAIL }
}

export const fetchWords = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchWordsLoading())
  axios
    .get(`${api}/words?limit=${10000}&page=${1}`, config)
    .then((res) => {
      const words = res.data
      dispatch(fetchWordsSuccess(words))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchWordsFail())
    })
}

export function fetchWordsLoading() {
  return { type: FETCH_WORDS }
}

export function fetchWordsSuccess(words) {
  return { type: FETCH_WORDS_SUCCESS, payload: words }
}

export function fetchWordsFail() {
  return { type: FETCH_WORDS_FAIL }
}

export const changePassword = (userID, newPassword) => async (dispatch, getState) => {
  const body = {
    password: newPassword,
    userID,
  }
  axios
    .post(`${api}/auth/change-password/`, body, config)
    .then((res) => {
      const word = res.data
      dispatch(createWordSuccess(word))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createWordFail())
    })
}

export function changePasswordSuccess() {
  return { type: CHANGE_PASSWORD_SUCCESS }
}

export function changePasswordFail() {
  return { type: CHANGE_PASSWORD_FAIL }
}

export const createWord = (word) => async (dispatch, getState) => {
  const body = {
    ...word,
  }
  if (word.examples) body.examples = word.examples.split('.,')
  axios
    .post(`${api}/words/`, body, config)
    .then((res) => {
      const word = res.data
      dispatch(createWordSuccess(word))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createWordFail())
    })
}

export function createWordSuccess(word) {
  return { type: CREATE_WORD_SUCCESS, payload: word }
}

export function createWordFail() {
  return { type: CREATE_WORD_FAIL }
}

export const editWord = (word) => async (dispatch, getState) => {
  const body = {
    ...word,
  }
  //if (word.examples) body.examples = word.examples.split('.,')
  delete body.id
  delete body._id
  if (body.createdAt) {
    delete body.createdAt
  }
  axios
    .put(`${api}/words/${word.id}`, body, config)
    .then((res) => {
      const word = res.data
      dispatch(editWordSuccess(word))
      dispatch(fetchWords())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editWordFail())
    })
}

export function editWordSuccess(word) {
  return { type: EDIT_WORD_SUCCESS, payload: word }
}

export function editWordFail() {
  return { type: EDIT_WORD_FAIL }
}

export const deleteWord = (word) => async (dispatch, getState) => {
  axios
    .delete(`${api}/words/${word.id}`, config)
    .then((res) => {
      dispatch(deleteWordSuccess())
      dispatch(fetchWords())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteWordFail())
    })
}

export function deleteWordSuccess() {
  return { type: DELETE_WORD_SUCCESS }
}

export function deleteWordFail() {
  return { type: DELETE_WORD_FAIL }
}

export const getUploadCredentials = (body) => async (dispatch, getState) => {
  axios
    .post(`${api}/vdocipher/`, body, config)
    .then((res) => {
      const credentials = res.data
      dispatch(getUploadCredentialsSuccess(credentials))
    })
    .catch((e) => {
      console.log(e)
      dispatch(getUploadCredentialsFail())
    })
}

export function getUploadCredentialsSuccess(credentials) {
  return { type: GET_UPLOAD_CREDENTIALS_SUCCESS, payload: credentials }
}

export function getUploadCredentialsFail() {
  return { type: GET_UPLOAD_CREDENTIALS_FAIL }
}

export const obtainOTP = (videoId) => async (dispatch, getState) => {
  const body = { video: videoId }
  axios
    .post(`${api}/vdocipher/otp`, body, config)
    .then((res) => {
      const otp = res.data
      dispatch(obtainOTPSuccess(otp))
    })
    .catch((e) => {
      console.log(e)
      dispatch(obtainOTPFail())
    })
}

export function obtainOTPSuccess(otp) {
  return { type: OBTAIN_OTP_SUCCESS, payload: otp }
}

export function obtainOTPFail() {
  return { type: OBTAIN_OTP_FAIL }
}

export const createSection = (section, file) => async (dispatch, getState) => {
  // const body = {
  //   ...section,
  // }

  const formData = new FormData()
  formData.append('section', JSON.stringify(section))

  if (file) formData.append('file', file)
  axios
    .post(`${api}/sections/`, formData, config)
    .then((res) => {
      const section = res.data
      dispatch(createSectionSuccess(section))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createSectionFail())
    })
}

export function createSectionSuccess(section) {
  return { type: CREATE_SECTION_SUCCESS, payload: section }
}

export function createSectionFail() {
  return { type: CREATE_SECTION_FAIL }
}

export const editSection = (section, file) => async (dispatch, getState) => {
  // const body = {
  //   ...section,
  // }
  // delete body.id
  // delete body._id
  // if (body.createdAt) {
  //   delete body.createdAt
  // }

  const formData = new FormData()
  formData.append('section', JSON.stringify(section))

  if (file) formData.append('file', file)

  axios
    .put(`${api}/sections/${section.id}`, formData, config)
    .then((res) => {
      const section = res.data
      dispatch(editSectionSuccess(section))
      // dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editSectionFail())
    })
}

export function editSectionSuccess(section) {
  return { type: EDIT_SECTION_SUCCESS, payload: section }
}

export function editSectionFail() {
  return { type: EDIT_SECTION_FAIL }
}

export const deleteSection = (section) => async (dispatch, getState) => {
  axios
    .delete(`${api}/sections/${section.id}`, config)
    .then((res) => {
      dispatch(deleteSectionSuccess())
      dispatch(fetchCourses())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteSectionFail())
    })
}

export function deleteSectionSuccess() {
  return { type: DELETE_SECTION_SUCCESS }
}

export function deleteSectionFail() {
  return { type: DELETE_SECTION_FAIL }
}

export const fetchSection = (id) => async (dispatch, getState) => {
  axios
    .get(`${api}/sections/${id}`, config)
    .then((res) => {
      dispatch(fetchSectionSuccess(res.data))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchSectionFail())
    })
}

export function fetchSectionSuccess(section) {
  return { type: FETCH_SECTION_SUCCESS, payload: section }
}

export function fetchSectionFail() {
  return { type: FETCH_SECTION_FAIL }
}

export function editFetchedSection(key, newValue) {
  return { type: EDIT_FETCHED_SECTION, payload: { key, newValue } }
}
export function replaceFetchedSection(section) {
  return { type: REPLACE_FETCHED_SECTION, payload: section }
}

export const fetchAllSections = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchAllSectionsLoading())
  axios
    .get(`${api}/sections?limit=${10000}&page=${1}/`, config)
    .then((res) => {
      const sections = res.data.results
      dispatch(fetchAllSectionsSuccess(sections))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchAllSectionsFail())
    })
}

export function fetchAllSectionsLoading() {
  return { type: FETCH_ALL_SECTIONS }
}

export function fetchAllSectionsSuccess(sections) {
  return { type: FETCH_ALL_SECTIONS_SUCCESS, payload: sections }
}

export function fetchAllSectionsFail() {
  return { type: FETCH_ALL_SECTIONS_FAIL }
}

export const fetchAllContent = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchAllContentLoading())
  axios
    .get(`${api}/content?limit=${10000}&page=${1}/`, config)
    .then((res) => {
      const content = res.data.results
      dispatch(fetchAllContentSuccess(content))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchAllContentFail())
    })
}

export function fetchAllContentLoading() {
  return { type: FETCH_ALL_CONTENT }
}

export function fetchAllContentSuccess(content) {
  return { type: FETCH_ALL_CONTENT_SUCCESS, payload: content }
}

export function fetchAllContentFail() {
  return { type: FETCH_ALL_CONTENT_FAIL }
}

export const createContent = (content) => async (dispatch, getState) => {
  const body = {
    ...content,
  }
  axios
    .post(`${api}/content/`, body, config)
    .then((res) => {
      const content = res.data
      dispatch(createContentSuccess(content))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createContentFail())
    })
}

export function createContentSuccess(content) {
  return { type: CREATE_CONTENT_SUCCESS, payload: content }
}

export function createContentFail() {
  return { type: CREATE_CONTENT_FAIL }
}

export const editContent = (content) => async (dispatch, getState) => {
  const body = {
    ...content,
  }
  delete body.id
  delete body._id
  if (body.createdAt) {
    delete body.createdAt
  }
  axios
    .put(`${api}/content/${content.id}`, body, config)
    .then((res) => {
      const content = res.data
      dispatch(editContentSuccess(content))
      dispatch(fetchAllContent())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editContentFail())
    })
}

export function editContentSuccess(content) {
  return { type: EDIT_CONTENT_SUCCESS, payload: content }
}

export function editContentFail() {
  return { type: EDIT_CONTENT_FAIL }
}

export const deleteContent = (content) => async (dispatch, getState) => {
  axios
    .delete(`${api}/content/${content.id}`, config)
    .then((res) => {
      dispatch(deleteContentSuccess())
      dispatch(fetchAllContent())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteContentFail())
    })
}

export function deleteContentSuccess() {
  return { type: DELETE_CONTENT_SUCCESS }
}

export function deleteContentFail() {
  return { type: DELETE_CONTENT_FAIL }
}

export const fetchContent = (id) => async (dispatch, getState) => {
  axios
    .get(`${api}/content/${id}`, config)
    .then((res) => {
      dispatch(fetchContentSuccess(res.data))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchContentFail())
    })
}

export function fetchContentSuccess(content) {
  return { type: FETCH_CONTENT_SUCCESS, payload: content }
}

export function fetchContentFail() {
  return { type: FETCH_CONTENT_FAIL }
}

export function uploadFileSuccess(file) {
  return { type: UPLOAD_FILE_SUCCESS, payload: file }
}

export function uploadFileFail() {
  return { type: UPLOAD_FILE_FAIL }
}

export const fetchAssessments = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchAssessmentsLoading())
  axios
    .get(`${api}/assessment?limit=${10000}&page=${1}`, config)
    .then((res) => {
      const assessments = res.data
      dispatch(fetchAssessmentsSuccess(assessments))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchAssessmentsFail())
    })
}

export function fetchAssessmentsLoading() {
  return { type: FETCH_ASSESSMENTS }
}

export function fetchAssessmentsSuccess(assessments) {
  return { type: FETCH_ASSESSMENTS_SUCCESS, payload: assessments }
}

export function fetchAssessmentsFail() {
  return { type: FETCH_ASSESSMENTS_FAIL }
}

export const createAssessment = (assessment) => async (dispatch, getState) => {
  const body = {
    ...assessment,
  }
  axios
    .post(`${api}/assessment/`, body, config)
    .then((res) => {
      const assessment = res.data
      dispatch(createAssessmentSuccess(assessment))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createAssessmentFail())
    })
}

export function createAssessmentSuccess(assessment) {
  return { type: CREATE_ASSESSMENT_SUCCESS, payload: assessment }
}

export function createAssessmentFail() {
  return { type: CREATE_ASSESSMENT_FAIL }
}

export const editAssessment = (assessment) => async (dispatch, getState) => {
  const body = {
    ...assessment,
  }
  delete body.id
  delete body._id
  if (body.createdAt) {
    delete body.createdAt
  }
  axios
    .put(`${api}/assessment/${assessment.id}`, body, config)
    .then((res) => {
      const assessment = res.data
      dispatch(editAssessmentSuccess(assessment))
      dispatch(fetchAssessments())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editAssessmentFail())
    })
}

export function editAssessmentSuccess(assessment) {
  return { type: EDIT_ASSESSMENT_SUCCESS, payload: assessment }
}

export function editAssessmentFail() {
  return { type: EDIT_ASSESSMENT_FAIL }
}

export const deleteAssessment = (assessment) => async (dispatch, getState) => {
  axios
    .delete(`${api}/assessment/${assessment.id}`, config)
    .then((res) => {
      dispatch(deleteAssessmentSuccess())
      dispatch(fetchAssessments())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteAssessmentFail())
    })
}

export function deleteAssessmentSuccess() {
  return { type: DELETE_ASSESSMENT_SUCCESS }
}

export function deleteAssessmentFail() {
  return { type: DELETE_ASSESSMENT_FAIL }
}

export const fetchPayments = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchPaymentsLoading())
  axios
    // .get(`${api}/payments?limit=${10000}&page=${1}`, config)
    .get(`${api}/payments`, config)

    .then((res) => {
      const payments = res.data
      dispatch(fetchPaymentsSuccess(payments))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchPaymentsFail())
    })
}

export function fetchPaymentsLoading() {
  return { type: FETCH_PAYMENTS }
}

export function fetchPaymentsSuccess(payments) {
  return { type: FETCH_PAYMENTS_SUCCESS, payload: payments }
}

export function fetchPaymentsFail() {
  return { type: FETCH_PAYMENTS_FAIL }
}

export const createPayment = (payment) => async (dispatch, getState) => {
  const body = {
    ...payment,
  }
  axios
    .post(`${api}/payments/`, body, config)
    .then((res) => {
      const payment = res.data
      dispatch(createPaymentSuccess(payment))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createPaymentFail())
    })
}

export function createPaymentSuccess(payment) {
  return { type: CREATE_PAYMENT_SUCCESS, payload: payment }
}

export function createPaymentFail() {
  return { type: CREATE_PAYMENT_FAIL }
}

export const editPayment = (payment) => async (dispatch, getState) => {
  const body = {
    ...payment,
  }
  delete body.id
  delete body._id
  if (body.createdAt) {
    delete body.createdAt
  }
  axios
    .put(`${api}/payments/${payment.id}`, body, config)
    .then((res) => {
      const payment = res.data
      dispatch(editPaymentSuccess(payment))
      dispatch(fetchPayments())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editPaymentFail())
    })
}

export function editPaymentSuccess(payment) {
  return { type: EDIT_PAYMENT_SUCCESS, payload: payment }
}

export function editPaymentFail() {
  return { type: EDIT_PAYMENT_FAIL }
}

export const deletePayment = (payment) => async (dispatch, getState) => {
  axios
    .delete(`${api}/payments/${payment.id}`, config)
    .then((res) => {
      dispatch(deletePaymentSuccess())
      dispatch(fetchPayments())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deletePaymentFail())
    })
}

export function deletePaymentSuccess() {
  return { type: DELETE_PAYMENT_SUCCESS }
}

export function deletePaymentFail() {
  return { type: DELETE_PAYMENT_FAIL }
}

export const fetchSubscriptions = (limit, offset) => async (dispatch, getState) => {
  dispatch(fetchSubscriptionsLoading())
  axios
    .get(`${api}/subscriptions?limit=${10000}&page=${1}`, config)
    .then((res) => {
      const subscriptions = res.data
      dispatch(fetchSubscriptionsSuccess(subscriptions))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchSubscriptionsFail())
    })
}

export function fetchSubscriptionsLoading() {
  return { type: FETCH_SUBSCRIPTIONS }
}

export function fetchSubscriptionsSuccess(subscriptions) {
  return { type: FETCH_SUBSCRIPTIONS_SUCCESS, payload: subscriptions }
}

export function fetchSubscriptionsFail() {
  return { type: FETCH_SUBSCRIPTIONS_FAIL }
}

export const createSubscription = (subscription) => async (dispatch, getState) => {
  const body = {
    ...subscription,
  }
  axios
    .post(`${api}/subscriptions/`, body, config)
    .then((res) => {
      const subscription = res.data
      dispatch(createSubscriptionSuccess(subscription))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createSubscriptionFail())
    })
}

export function createSubscriptionSuccess(subscription) {
  return { type: CREATE_SUBSCRIPTION_SUCCESS, payload: subscription }
}

export function createSubscriptionFail() {
  return { type: CREATE_SUBSCRIPTION_FAIL }
}

export const editSubscription = (subscription) => async (dispatch, getState) => {
  const body = {
    ...subscription,
  }
  delete body.id
  delete body._id
  if (body.createdAt) {
    delete body.createdAt
  }
  axios
    .put(`${api}/subscriptions/${subscription.id}`, body, config)
    .then((res) => {
      const subscription = res.data
      dispatch(editSubscriptionSuccess(subscription))
      dispatch(fetchSubscriptions())
    })
    .catch((e) => {
      console.log(e)
      dispatch(editSubscriptionFail())
    })
}

export function editSubscriptionSuccess(subscription) {
  return { type: EDIT_SUBSCRIPTION_SUCCESS, payload: subscription }
}

export function editSubscriptionFail() {
  return { type: EDIT_SUBSCRIPTION_FAIL }
}

export const deleteSubscription = (subscription) => async (dispatch, getState) => {
  axios
    .delete(`${api}/subscriptions/${subscription.id}`, config)
    .then((res) => {
      dispatch(deleteSubscriptionSuccess())
      dispatch(fetchSubscriptions())
    })
    .catch((e) => {
      console.log(e)
      dispatch(deleteSubscriptionFail())
    })
}

export function deleteSubscriptionSuccess() {
  return { type: DELETE_SUBSCRIPTION_SUCCESS }
}

export function deleteSubscriptionFail() {
  return { type: DELETE_SUBSCRIPTION_FAIL }
}
export const fetchUsersStatistics =
  (role = 'user', year = 2023, allTime = false) =>
  async (dispatch, getState) => {
    dispatch(fetchUsersStatisticsLoading())
    axios
      .get(`${api}/users/statistics?role=${role}&startingYear=${year}&isAllTime=${allTime}`, config)
      .then((res) => {
        const statistics = res.data
        dispatch(fetchUsersStatisticsSuccess(statistics))
      })
      .catch((e) => {
        console.log(e)
        dispatch(fetchUsersStatisticsFail())
      })
  }

export function fetchUsersStatisticsLoading() {
  return { type: FETCH_USERS_STATISTICS }
}

export function fetchUsersStatisticsSuccess(statistics) {
  return { type: FETCH_USERS_STATISTICS_SUCCESS, payload: statistics }
}

export function fetchUsersStatisticsFail() {
  return { type: FETCH_USERS_STATISTICS_FAIL }
}
export const createUser = (data) => async (dispatch, getState) => {
  dispatch(createUserLoading())
  axios
    .post(`${api}/auth/register`, data, config)
    .then((res) => {
      const user = res.data
      dispatch(createUserSuccess(user))
    })
    .catch((e) => {
      console.log(e)
      dispatch(createUserFail())
    })
}

export function createUserLoading() {
  return { type: CREATE_USER }
}

export function createUserSuccess(user) {
  return { type: CREATE_USER_SUCCESS, payload: user }
}

export function createUserFail() {
  return { type: CREATE_USER_FAIL }
}

export const fetchPaymentsStatistics = () => async (dispatch, getState) => {
  dispatch(fetchPaymentsStatisticsLoading())
  axios
    .get(`${api}/payments/statistics`, config)
    .then((res) => {
      const statistics = res.data
      dispatch(fetchPaymentsStatisticsSuccess(statistics))
    })
    .catch((e) => {
      console.log(e)
      dispatch(fetchPaymentsStatisticsFail())
    })
}

export function fetchPaymentsStatisticsLoading() {
  return { type: FETCH_PAYMENTS_STATISTICS }
}

export function fetchPaymentsStatisticsSuccess(statistics) {
  return { type: FETCH_PAYMENTS_STATISTICS_SUCCESS, payload: statistics }
}

export function fetchPaymentsStatisticsFail() {
  return { type: FETCH_PAYMENTS_STATISTICS_FAIL }
}

const composeEnhancers = composeWithDevTools({})
const store = createStore(reducer, {}, composeEnhancers(applyMiddleware(reduxThunk)))
export default store
